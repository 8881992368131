<script lang="ts">
import { defineComponent, toRefs, computed, h } from 'vue'
import { useLocalization } from '#imports'
import { useConfig } from '@/stores/config'

import type { PropType } from 'vue'

export type PriceSize = 'lg' | 'base' | 'sm' | 'xs' | '2xs' | undefined
export type PriceVariant = 'sale' | 'end' | undefined
export type PriceDirection = 'column' | undefined

export default defineComponent({
  name: 'APrice',
  props: {
    regularPrice: {
      type: [String, Number],
      required: true
    },
    discountPrice: {
      type: [String, Number],
      default: ''
    },
    size: {
      type: String as PropType<PriceSize>,
      default: undefined
    },
    variant: {
      type: String as PropType<PriceVariant>,
      default: undefined
    },
    currency: {
      type: String,
      default: 'RUB'
    },
    direction: {
      type: String as PropType<PriceDirection>,
      default: undefined
    },
    isOpt: Boolean,
    withCount: Boolean
  },
  setup (props) {
    const { size, variant, currency, direction, isOpt } = toRefs(props)

    const { currentRegion } = useConfig()
    const { locale, t } = useLocalization()
    const currencyFormat = new Intl.NumberFormat(
      locale.value, { style: 'currency', currency: currentRegion.value.defaultCurrency, minimumFractionDigits: 0 }
    )

    const defaultCurrency = currentRegion.value?.defaultCurrency

    const classes = computed(() => {
      const list = ['price']

      if (size.value) { list.push(`price--${size.value}`) }
      if (direction.value) { list.push(`price--${direction.value}`) }
      if (isOpt.value) { list.push('price--opt') } else if (variant.value) { list.push(`price--${variant.value}`) }

      return list.join(' ')
    })

    const formatPrice = (value: string | number): string => {
      if (defaultCurrency?.toLowerCase() !== currency.value?.toLowerCase()) {
        return new Intl.NumberFormat(
          locale.value,
          { style: 'currency', currency: currency.value, minimumFractionDigits: 0 }
        ).format(+value)
      }

      return currencyFormat.format(+value)
    }

    const formattedPrice = computed(() => {
      return props.regularPrice === '-'
        ? formatPrice(0).replace('0', '')
        : formatPrice(props.regularPrice)
    })

    return () =>
      isOpt.value
        ? h('div', { class: classes.value }, [
          h('div', t('other.price-opt')),
          h('p', props.discountPrice ? formatPrice(props.discountPrice) : formatPrice(props.regularPrice))
        ])
        : variant.value !== 'sale'
          ? h('p', { class: classes.value }, [formattedPrice.value, props.withCount ? h('span', ` / ${t('atoms.priceone.unit')}`) : ''])
          : h('div', { class: classes.value }, [
            h('p', formatPrice(props.discountPrice)),
            h('p', formatPrice(props.regularPrice))
          ])
  }
})
</script>

<style lang="postcss" >
/*
  Local custom properties to customize price from context:
  --price-color
  --price-fs
  --price-fs-sale
  --price-fw
  --price-fw-sale
  --price-line-height
  --price-line-height-sale
  --price-spacer
*/
p.price:first-of-type , .price p:first-of-type{
  color: var(--price-color, var(--color-neutral));
  font-weight: var(--price-fw, 700);
  font-size: var(--price-fs, var(--fs-base));
  line-height: var(--price-line-height, var(--spacer-base));

  & > span {
    position: relative;
    bottom: var(--price-pcs-margin, 0);
    color: var(--price-color, var(--color-neutral));
    font-weight: var(--price-fw, 700);
    font-size: var(--price-pcs-fs, var(--fs-xs));
  }
}

.price--sale {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: flex-start;

  & > p:first-child {
    margin-right: var(--price-spacer, var(--spacer-4xs));
    color: var(--color-danger-dark);
  }

  & > p:last-child {
    color: var(--color-neutral-600);
    font-weight: var(--price-fw-sale, 600);
    font-size: var(--price-fs-sale, var(--fs-xs));
    line-height: var(--price-line-height-sale, var(--spacer-xs));
    text-decoration-line: line-through;
  }
}

.price--opt {
  --price-color: var(--color-white);

  display: flex;
  gap: 0 var(--spacer-4xs);
  align-items: baseline;
  justify-content: flex-start;

  & > div {
    display: flex;
    gap: var(--spacer-4xs);
    align-items: center;
    padding: 0 var(--spacer-3xs);
    border-radius: var(--border-radius-2xs);
    background: var(--color-success-dark);
    color: var(--price-color, var(--color-neutral));
    font-weight: var(--price-fw, 700);
    font-size: var(--price-fs, var(--fs-base));
    line-height: var(--price-line-height, var(--spacer-base));

    &::before {
      content: "";
      display: block;
      width: var(--price-lock-size, var(--spacer-xs));
      height: var(--price-lock-size, var(--spacer-xs));
      background-image: url('/assets/images/opt/lock.svg');
      background-size: cover;
    }
  }

  & > p:last-child {
    color: var(--color-neutral-600);
    font-weight: var(--price-fw-sale, 600);
    font-size: var(--price-fs-sale, var(--fs-xs));
    line-height: var(--price-line-height-sale, var(--spacer-xs));
    text-decoration-line: line-through;
  }
}

.price--end {
  --price-color: var(--color-neutral-700);
}

.price--column {
  flex-direction: column;
  align-items: flex-start;
}

.price--lg {
  --price-fs: var(--fs-5xl);
  --price-fs-sale: var(--fs-lg);
  --price-fw: 700;
  --price-fw-sale: 700;
  --price-line-height: var(--spacer-3xl);
  --price-line-height-sale: var(--spacer-base);
  --price-spacer: var(--spacer-2xs);
  --price-lock-size: var(--spacer-lg);
  --price-pcs-fs: var(--fs-lg);
  --price-pcs-margin: var(--spacer-5xs);
}

.price--base {
  --price-fs: var(--fs-2xl);
  --price-fs-sale: var(--fs-sm);
  --price-fw: 700;
  --price-fw-sale: 500;
  --price-line-height: var(--spacer-lg);
  --price-line-height-sale: var(--spacer-sm);
  --price-spacer: var(--spacer-3xs);
  --price-lock-size: var(--spacer-sm);
}

.price--sm {
  --price-fs: var(--fs-xl);
  --price-fs-sale: var(--fs-sm);
  --price-fw: 700;
  --price-fw-sale: 500;
  --price-line-height: var(--spacer-lg);
  --price-line-height-sale: var(--spacer-sm);
  --price-spacer: var(--spacer-3xs);
  --price-lock-size: var(--spacer-sm);
}

.price--xs {
  --price-fs: var(--fs-lg);
  --price-fs-sale: var(--fs-xs);
  --price-fw: 700;
  --price-fw-sale: 600;
  --price-line-height: var(--spacer-base);
  --price-line-height-sale: var(--spacer-xs);
  --price-spacer: var(--spacer-3xs);
  --price-lock-size: var(--spacer-xs);
}

.price--2xs {
  --price-fs: var(--fs-sm);
  --price-fs-sale: var(--fs-xs);
  --price-fw: 700;
  --price-fw-sale: 600;
  --price-line-height: var(--spacer-base);
  --price-line-height-sale: var(--spacer-xs);
  --price-spacer: var(--spacer-3xs);
  --price-lock-size: var(--spacer-xs);
}
</style>
